<template>
  <div>
    <b-modal
      id="modalCustomers"
      hide-footer
      :hide-header="hideHeader"
      centered
      size="lg"
      v-model="modalShow"
    >
      <h3 class="font-weight-bolder mb-1">{{ $i18n.messages.SelectClient }}</h3>
      <b-form @submit.prevent>
        <b-input-group class="mb-2 mr-sm-2 mb-sm-2">
          <b-form-input
            id="input-client"
            v-model="searchCustomer"
            :placeholder="$i18n.messages.FindClient"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="validationForm">{{
              $i18n.messages.Buscar
            }}</b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form>
      <div>
        <!--  <b-table
          :fields="fieldsFrecuentes"
          :items="listCustomersFrecuentes"
          v-if="!searchCustomer"
        >
          <template #cell(Nombre)="data">
            <span class="cursor-pointer" @click="setCurrentCustomer(data.item)">
              {{ data.value }}
              <feather-icon
                class="text-body float-right"
                icon="ShoppingCartIcon"
                size="16"
                v-if="data.item.carrito"
              />
            </span>
          </template>
        </b-table> -->
        <b-table
          striped
          responsive
          :stacked="stacked"
          :fields="fields"
          :items="listCustomers"
        >
          <template #cell(Nombre)="data">
            <feather-icon
              class="text-body float-right"
              icon="ShoppingCartIcon"
              size="16"
              v-if="data.item.carrito"
            />
            <span class="cursor-pointer" @click="setCurrentCustomer(data.item)">
              <span v-html="data.value"></span>
            </span>
          </template>
          <template #cell(Cliente)="data">
            <span class="cursor-pointer" @click="setCurrentCustomer(data.item)">
              {{ data.value }}
            </span>
          </template>
        </b-table>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BButton,
  BFormInput,
  BInputGroup,
  BModal,
  BInputGroupAppend,
  VBModal,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  components: {
    BModal,
    BForm,
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
  },
  computed: {
    ...mapGetters({
      listCustomers: "customers/getListCustomers",
      // listCustomersFrecuentes: "customers/getListCustomersFrecuentes",
      currentCustomer: "customers/getCurrentCustomer",
      user: "auth/getUser",
    }),
    modalShow: {
      get() {
        return this.$store.getters["customers/getShowModalCustomers"];
      },
      set(val) {
        this.$store.commit("customers/setShowModalCustomers", val);
      },
    },
    hideHeader() {
      if (this.currentCustomer) {
        return false;
      }
      return true;
    },
  },
  watch: {
    modalShow() {
      if (this.modalShow) {
        if (this.user.rol_id === 0 || this.user.rol_id === 2) {
          this.searchCustomersAdmin(this.searchCustomer);
        } else {
          this.searchCustomers(this.searchCustomer);
        }
      }
    },
  },
  mounted() {
    this.stacked = window.innerWidth <= 992
    window.addEventListener('resize', () => {
      this.stacked = window.innerWidth <= 992
    })
  },
  created() {
    console.log(this.listCustomers);
    //  this.modalShow = false;
    if (this.currentCustomer === "" || this.currentCustomer === null) {
      if (this.user.rol_id === 0 || this.user.rol_id === 2) {
        this.modalShow = false;
      } else {
        console.log("ENTRA1");
        setTimeout(() => {
          console.log("ENTRA2");
          this.searchCustomers(this.searchCustomer);
        }, 1000);
        console.log("ENTRA3");
        this.modalShow = true;
        //
      }
    } else {
      this.modalShow = false;
    }
  },
  data() {
    return {
      stacked: false,
      searchCustomer: "",
      fields: [
        {
          key: "Nombre",
          label: this.$i18n.messages.Name,
        },
        {
          key: "Cliente",
          label: this.$i18n.messages.Code,
        },
        {
          key: "Pedido Minimo",
          label: this.$i18n.messages.MinOrder,
        },
        {
          key: "Cargo pedido minimo",
          label: this.$i18n.messages.MinOrderCharge,
        },
      ],
      /*   fieldsFrecuentes: [
        {
          key: "Nombre",
          label: this.$i18n.messages.UsoFrecuente,
        },
      ], */
    };
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    ...mapActions({
      searchCustomers: "customers/searchCustomers",
      searchCustomersAdmin: "customers/searchCustomersAdmin",
    }),
    ...mapMutations({
      setCurrentCustomer: "customers/setCurrentCustomer",
    }),
    validationForm() {
      if (this.user.rol_id === 0 || this.user.rol_id === 2) {
        this.searchCustomersAdmin(this.searchCustomer);
      } else {
        this.searchCustomers(this.searchCustomer);
      }
    },
  },
};
</script>
